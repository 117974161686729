<template>
  <div>
    <v-form>
      <p class="mb-3">
        Identify whether each of the molecules shown below denotes the <i>R-</i> or
        <i>S-</i> enantiomer of the compound.
      </p>

      <v-simple-table style="max-width: 391px">
        <thead>
          <tr>
            <th style="font-size: 15px">Molecule</th>
            <th style="font-size: 15px"><i>R</i></th>
            <th style="font-size: 15px"><i>S</i></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="my-2">
              <img
                src="/img/assignments/1-phenylEthanolKekule.png"
                alt=""
                class="my-2"
                style="width: 139px"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputA" :disabled="!allowEditing">
                <v-radio key="r" value="r" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputA" :disabled="!allowEditing">
                <v-radio key="s" value="s" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td class="my-2">
              <img src="/img/assignments/hbtmKekule.png" alt="" class="my-2" style="width: 97px" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputB" :disabled="!allowEditing">
                <v-radio key="r" value="r" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputB" :disabled="!allowEditing">
                <v-radio key="s" value="s" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI_51LPL6_Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
      },
    };
  },
};
</script>
